import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import {
  AgreementType,
  CommunityAgreementSecurityActions,
  CommunityAgreementSecurityResource,
  UserAgreementDto,
} from '@greco/community-agreements';
import { PaymentStatus } from '@greco/finance-payments';
import { Community } from '@greco/identity-communities';
import { User } from '@greco/identity-users';
import type { CheckoutPreview } from '@greco/nestjs-sales-products';
import type { CreateSubscriptionDto } from '@greco/nestjs-sales-subscriptions';
import { UserService } from '@greco/ngx-identity-auth';
import { CommunityService } from '@greco/ngx-identity-communities';
import { SignatureService } from '@greco/ngx-identity-users';
import {
  AddonsService,
  CheckoutService,
  InventoryService,
  ProductAgreementAddonsService,
  ProductConditionService,
  VariantsService,
} from '@greco/ngx-sales-products';
import { PurchaseService } from '@greco/ngx-sales-purchases';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import {
  AddonType,
  InventoryProductAddon,
  ProductConditionEvaluations,
  ProductVariant,
  ProductVariantInventory,
  UserAvailabilityAddon,
  VariantResource,
  VariantResourceAction,
} from '@greco/sales-products';
import { Purchase, PurchaseResource, PurchaseResourceAction, PurchaseStatus } from '@greco/sales-purchases';
import {
  Subscription,
  SubscriptionResource,
  SubscriptionResourceAction,
  SubscriptionStatus,
} from '@greco/sales-subscriptions';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { AccountLinkingService } from '@greco/web-account-linking';
import moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'greco-checkout-page',
  templateUrl: './checkout.page.html',
  styleUrls: ['./checkout.page.scss'],
})
export class CheckoutPage implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private snacks: MatSnackBar,
    private userSvc: UserService,
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private formGroup: FormBuilder,
    private addonSvc: AddonsService,
    private variantSvc: VariantsService,
    private securitySvc: SecurityService,
    private checkoutSvc: CheckoutService,
    private purchaseSvc: PurchaseService,
    private inventorySvc: InventoryService,
    private linkSvc: AccountLinkingService,
    private communitySvc: CommunityService,
    private signatureSvc: SignatureService,
    private conditionSvc: ProductConditionService,
    private usageSvc: ProductAgreementAddonsService
  ) {}

  confirming = false;

  paymentMethodControl = new FormControl(null, Validators.required);

  dateSelection = new FormControl(moment().add(1, 'days').toDate(), Validators.required);
  ignoreEnrolmentFee = new FormControl(false, Validators.required);

  dateRadioButton = new FormControl('now');
  minimalDate = moment().add(1, 'days').toDate(); // TODO: support retroactive starts.
  maximalDate = moment().add(5, 'years').toDate();

  signature: string | null = null;

  referredById?: string | null = null;
  transferredFromId?: string | null = null;

  checked = false;

  loaded = 0;

  agreementData: UserAgreementDto[] = [];
  self: User | null = null;

  refresh$ = new BehaviorSubject(null);
  @PropertyListener('hideIgnoreEnrolmentFee') private _hideIgnoreEnrolmentFee$ = new BehaviorSubject<boolean>(false);
  @Input() hideIgnoreEnrolmentFee?: boolean;

  @PropertyListener('isStaff') private _isStaff$ = new BehaviorSubject(false);
  @Input() isStaff = false;

  @PropertyListener('user') private _user$ = new ReplaySubject<User>(1);
  @Input() user?: User;

  @PropertyListener('purchasedBy') private _purchasedBy$ = new ReplaySubject<User>(1);
  @Input() purchasedBy?: User;

  @PropertyListener('variantId') private _variantId$ = new BehaviorSubject<string | null>(null);
  @Input() variantId?: string;

  @PropertyListener('productId') private _productId$ = new BehaviorSubject<string | null>(null);
  @Input() productId?: string;

  @Input() preventRedirect = false;

  @Output() purchaseSuccess: EventEmitter<void> = new EventEmitter<void>();

  agreementsForm = this.formGroup.group({});
  controlsConfigDetails: { [key: string]: any } = {};

  private _variantIds$ = this.route.queryParamMap.pipe(
    map(params => params.getAll('items') || []),
    shareReplay(1)
  );

  allowTerminals = false;
  allowCardPayments = true;
  allowBankPayments = false;

  hideSignLater = false;

  initialUser: User | null = null;

  variants$ = combineLatest([this._variantId$, this._variantIds$]).pipe(
    tap(() => {
      this.allowTerminals = false;
      this.allowCardPayments = true;
      this.allowBankPayments = false;
    }),
    switchMap(async ([variantId, _variantIds]) => {
      // Unique list of variant Ids
      const variantIds = [..._variantIds, ...(variantId ? [variantId] : [])].filter((v, i, a) => i === a.indexOf(v));

      const variants: ProductVariant[] = variantIds.length
        ? await Promise.all(variantIds.map(id => this.variantSvc.getOneVariantWithoutProduct(id)))
        : [];

      this.allowTerminals = variants.every(v => !v.recurrence?.frequency);
      this.allowCardPayments = variants.some(v => v.paymentTypes?.includes('card'));
      this.allowBankPayments = variants.some(v => v.paymentTypes?.includes('bank'));

      if (!this.productId) this.productId = variants[0]?.productId;

      return variants;
    })
  );

  canSellHiddenVariant$ = this.variants$.pipe(
    switchMap(async variants => {
      const hiddenVariants: ProductVariant[] = [];
      variants.forEach(variant => {
        if (variant.isHidden) {
          hiddenVariants.push(variant);
        }
      });
      if (hiddenVariants.length > 0) {
        return await this.securitySvc.hasAccess(VariantResource.key, VariantResourceAction.SELL_HIDDEN, {
          communityId: variants[0].product?.community.id,
        });
      } else {
        return true;
      }
    })
  );

  preview$: Observable<CheckoutPreview | null> = combineLatest([
    this._user$,
    this._purchasedBy$,
    this._variantId$,
    this._variantIds$,
    this.paymentMethodControl.valueChanges.pipe(startWith(null)),
    this.dateSelection.valueChanges.pipe(startWith(this.dateSelection.value)),
    this.userSvc.getSelf(),
    this.dateRadioButton.valueChanges.pipe(startWith(this.dateRadioButton.value)),
    this.ignoreEnrolmentFee.valueChanges.pipe(startWith(this.ignoreEnrolmentFee.value)),
    this._isStaff$,
    this.refresh$,
  ]).pipe(
    takeUntil(this.onDestroy$),
    tap(() => (this.loading = true)),

    switchMap(
      async ([
        user,
        purchasedBy,
        variantIdInput,
        variantIds,
        paymentMethod,
        dateSelection,
        self,
        dateRadioButton,
        ignoreEnrolmentFee,
        isStaff,
      ]) => {
        if (!user || !purchasedBy || (!variantIdInput && !variantIds?.length)) return null;
        if (!this.initialUser) this.initialUser = user;

        try {
          return await this.checkoutSvc.preview({
            ...(isStaff ? { soldById: self.id } : {}),
            variants: variantIdInput
              ? [{ variantId: variantIdInput, quantity: 1 }]
              : variantIds.map((variantId: any) => ({ variantId, quantity: 1 })),
            paymentMethodId: paymentMethod?.id,
            createdById: self?.id,
            userId: user.id,
            purchasedById: purchasedBy.id,
            scheduleDate: dateRadioButton === 'now' ? moment().toDate() : dateSelection,
            ignoreEnrolmentFee: ignoreEnrolmentFee,
          });
        } catch (err) {
          console.error({ err });
          return null;
        }
      }
    ),
    // catchError(() => of(null)),
    tap(() => (this.loading = false)),
    shareReplay(1)
  );

  community$: Observable<Community | null> = this.preview$.pipe(
    switchMap(async preview => {
      if (!preview) return null;
      return await this.communitySvc.getCommunityByAccountId(preview.purchase.account.id);
    }),
    shareReplay(1)
  );

  canIgnoreEnrolmentFee$ = combineLatest([this._hideIgnoreEnrolmentFee$, this.community$]).pipe(
    switchMap(async ([hideIgnoreEnrolment, community]) => {
      if (hideIgnoreEnrolment) return false;

      const user = await this.userSvc.getSelf();
      const accountId = community?.financeAccountId;

      return await this.securitySvc.hasAccess(
        SubscriptionResource.key,
        SubscriptionResourceAction.IGNORE_ENROLMENT_FEE,
        {
          userId: this.user?.id,
          accountId: accountId,
          createdById: user?.id,
        }
      );
    }),
    shareReplay(1)
  );

  canPurchaseOutOfStock$ = this.community$.pipe(
    switchMap(async community => {
      const user = await this.userSvc.getSelf();
      const accountId = community?.financeAccountId;

      return await this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.PURCHASE_OUT_OF_STOCK, {
        userId: this.user?.id,
        accountId: accountId,
        createdById: user?.id,
      });
    }),
    shareReplay(1)
  );

  sectionTitle$ = this.preview$.pipe(
    map(preview => preview?.purchase.account.name),
    startWith(null),
    map(accountName => (accountName ? `Your ${accountName} Purchase` : 'Your Purchase'))
  );

  canManageSchedule$ = this.community$.pipe(
    switchMap(async community => {
      const user = await this.userSvc.getSelf();
      const accountId = community?.financeAccountId;

      return await this.securitySvc.hasAccess(SubscriptionResource.key, SubscriptionResourceAction.START_LATER, {
        userId: this.user?.id,
        accountId: accountId,
        createdById: user?.id,
      });
    }),
    shareReplay(1)
  );

  canZeroPurchase$ = this.community$.pipe(
    switchMap(async community => {
      const user = await this.userSvc.getSelf();
      const accountId = community?.financeAccountId;

      return await this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.ZERO_DOLLAR_PURCHASE, {
        userId: this.user?.id,
        accountId: accountId,
        createdById: user?.id,
      });
    }),
    shareReplay(1)
  );

  canZeroSubscription$ = this.community$.pipe(
    switchMap(async community => {
      const user = await this.userSvc.getSelf();
      const accountId = community?.financeAccountId;

      return await this.securitySvc.hasAccess(
        SubscriptionResource.key,
        SubscriptionResourceAction.ZERO_DOLLAR_SUBSCRIPTION,
        {
          userId: this.user?.id,
          accountId: accountId,
          createdById: user?.id,
        }
      );
    }),
    shareReplay(1)
  );

  canContinueWithoutSigning$ = combineLatest([this._user$, this._purchasedBy$, this.community$, this._isStaff$]).pipe(
    switchMap(async ([purchasedFor, purchasedBy, community, isStaff]) => {
      const controls = this.agreementsForm.controls;

      if (isStaff) {
        const user = await this.userSvc.getSelf();
        const accountId = community?.financeAccountId;

        const canLeaveUnsigned = await this.securitySvc.hasAccess(
          CommunityAgreementSecurityResource.key,
          CommunityAgreementSecurityActions.LEAVE_UNSIGNED,
          {
            userId: user?.id,
            accountId: accountId,
            createdById: user?.id,
          }
        );

        if (canLeaveUnsigned) {
          Object.keys(controls).forEach(control =>
            !control.includes('unsigned') ? this.setAgreementRequired(control, false) : null
          );
        }

        return canLeaveUnsigned;
      } else {
        if (purchasedFor?.id !== purchasedBy?.id) {
          Object.keys(controls).forEach(control =>
            !control.includes('unsigned') ? this.setAgreementRequired(control, false) : null
          );

          this.hideSignLater = true;
        } else {
          Object.keys(controls).forEach(control =>
            !control.includes('unsigned') ? this.setAgreementRequired(control, true) : null
          );
        }

        if (purchasedFor.id === purchasedBy.id) return false;

        const link = await this.linkSvc.getLink(purchasedBy.id, purchasedFor.id);
        if (purchasedBy.id !== purchasedFor.id && link) return true;

        return false;
      }
    }),
    shareReplay(1)
  );

  userConditionContext$ = this._user$.pipe(
    switchMap(user => this.conditionSvc.getConditionContext(user?.id)),
    shareReplay(1)
  );

  availabilityExtension$ = this.variants$.pipe(
    // Get a unique list of productIds
    map(variants => [...new Set(variants.map(variant => variant.productId))]),

    // Avoid querying extensions if the productIds list didn't change
    distinctUntilChanged((prev, next) => prev.join('') === next.join('')),

    // Map the productIds to their extension (if it exists and is enabled)
    switchMap(async ids => {
      if (!ids.length) return [];
      const extensions = await Promise.all(ids.map(id => this.addonSvc.getOneByType(id, AddonType.UserAvailability)));
      return extensions.filter(ext => ext && ext.enabled) as UserAvailabilityAddon[];
    }),

    shareReplay(1)
  );

  conditionCanBuy$ = combineLatest([this.userConditionContext$, this.availabilityExtension$]).pipe(
    map(([context, extensions]) => {
      const initialValue: ProductConditionEvaluations = { result: true, errors: [], messages: [] };

      return extensions.reduce((acc, extension) => {
        const { result, errors, messages } = this.conditionSvc.evaluateAll(extension, context);

        acc.messages = [...new Set([...acc.messages, ...messages])];
        acc.errors = [...new Set([...acc.errors, ...errors])];
        if (!result) acc.result = false;

        return acc;
      }, initialValue);
    })
  );

  agreements$ = this.variants$.pipe(
    switchMap(async variants => {
      const productId = variants[0]?.productId;
      const addon = await this.addonSvc.getOneByType(productId, AddonType.Agreement);
      let usage = addon ? await this.usageSvc.getManyByProdAddonId(addon.id) : null;
      if (usage) usage = usage.filter(usage => usage.productAddOn?.enabled);
      return usage;
    }),
    tap(agreements => {
      if (this.loaded === 0 && agreements) {
        agreements?.forEach(agreement => {
          switch (agreement.agreement?.agreementType) {
            case AgreementType.AUTO_CHECKBOX: {
              this.controlsConfigDetails[`${agreement.id}`] = [true, Validators.requiredTrue];
              this.agreementData = [...this.agreementData, { agreementId: agreement.agreementId, signed: true }];
              break;
            }
            case AgreementType.CHECKBOX: {
              this.controlsConfigDetails[`${agreement.id}`] = [false, Validators.requiredTrue];
              this.agreementData = [...this.agreementData, { agreementId: agreement.agreementId, signed: false }];
              break;
            }
            case AgreementType.DIGITAL_SIGNATURE: {
              this.controlsConfigDetails[`${agreement.id}`] = [false, Validators.requiredTrue];
              this.agreementData = [...this.agreementData, { agreementId: agreement.agreementId, signed: false }];
              break;
            }
          }
        });
        this.loaded++;
      }
      this.agreementsForm = this.formGroup.group({ ...this.controlsConfigDetails });
    })
  );

  inventories$ = this.preview$.pipe(
    switchMap(async preview => {
      const inventories: Map<
        string,
        {
          inventory?: ProductVariantInventory;
          addon?: InventoryProductAddon;
          variantId: string;
          itemQuantity: number;
        }
      > = new Map();
      if (!preview) return null;

      let outOfStock = false;
      let outOfStockMessage = null;

      for (const item of preview.purchase.items) {
        if (!(item as any)?.variant) continue;
        const variant: ProductVariant = (item as any).variant;

        const [addon, inventory] = await Promise.all([
          this.addonSvc.getOneByType(variant.productId, AddonType.Inventory) as Promise<InventoryProductAddon>,
          this.inventorySvc.getVariantInventory(variant.id),
        ]);

        if (addon?.enabled) {
          inventories.set(variant.id, { variantId: variant.id, addon, inventory, itemQuantity: item.quantity });
          if (!inventory?.enabled || item.quantity > inventory.availableInventoryCount) {
            outOfStock = true;
            if (!outOfStockMessage) outOfStockMessage = addon.outOfStockMessage;
          }
        }
      }

      return { inventories, outOfStock, outOfStockMessage };
    })
  );

  loading = true;

  setCheck(usageId: string, agreementId: string) {
    const checked = this.agreementsForm.get(usageId)?.value;

    this.agreementsForm.patchValue({ [`${usageId}`]: !checked });

    const index = this.agreementData.findIndex(agreement => agreement.agreementId == agreementId);
    this.agreementData[index] = { agreementId, signed: !checked };
  }

  setAgreementRequired(usageId: string, required: boolean) {
    const control = this.agreementsForm.controls[usageId];

    if (required) control.setValidators(Validators.requiredTrue);
    else {
      control.setErrors(null);
      control.clearValidators();
    }
  }

  setSignature(event: string, usageId: string, agreementId: string) {
    const index = this.agreementData.findIndex(agreement => agreement.agreementId == agreementId);

    this.signature = event;
    if (event != '') {
      this.agreementsForm.patchValue({ [`${usageId}`]: true });
      this.agreementData[index] = { agreementId, signed: true };
    } else {
      this.agreementsForm.patchValue({ [`${usageId}`]: false });
      this.agreementData[index] = { agreementId, signed: false };
    }
  }

  processQueryParamItems(_itemsParam: string) {
    return [];
  }

  async confirmPurchase(preview: CheckoutPreview | null, messages: string[] | null) {
    if (!preview || preview.errors.length) return;

    if (messages && messages.length) {
      let messagesString = '';
      messages.forEach(message => (messagesString += message + `<br>`));

      const dialog = this.matDialog.open(SimpleDialog, {
        data: {
          showCloseButton: false,
          title: 'Before you purchase:',
          content: messagesString,
          buttons: [
            { label: 'Cancel', role: 'no' },
            { label: 'Confirm', role: 'yes', color: 'primary' },
          ],
        } as DialogData,
        width: '500px',
        maxWidth: '100%',
      });

      if ((await toPromise(dialog.afterClosed())) === 'no') return;
    }

    const agreementControlKeys = Object.keys(this.agreementsForm.controls);
    const leftUnsigned = agreementControlKeys.some(key => !this.agreementsForm.controls[key].value);

    if (leftUnsigned) {
      const dialog = this.matDialog.open(SimpleDialog, {
        data: {
          showCloseButton: false,
          title: 'Unsigned Agreements',
          content:
            'There are currently unsigned agreements for this purchase. \nContinuing will create the purchase with an unsigned agreement. The member with the unsigned agreement will not be able to book classes or make other purchases until their agreement is signed. \n\nContinue?',
          buttons: [
            { label: 'Cancel', role: 'no' },
            { label: 'Confirm', role: 'yes', color: 'primary' },
          ],
        } as DialogData,
        width: '500px',
        maxWidth: '100%',
      });

      if ((await toPromise(dialog.afterClosed())) === 'no') return;
    }

    this.confirming = true;

    if (this.dateRadioButton.value == 'future') {
      const formattedStartDate: Date = moment((this.dateSelection.value as Date).setHours(4, 0, 0, 0)).toDate();
      (preview.dto as CreateSubscriptionDto).startDate = formattedStartDate;
    }

    if (this.dateRadioButton.value == 'now') {
      const formattedStartDate: Date = moment().toDate();
      (preview.dto as CreateSubscriptionDto).startDate = formattedStartDate;
    }

    try {
      // Save signature
      if (this.user && this.signature) {
        let signature: any = null;

        try {
          signature = await this.signatureSvc.getOne(this.user?.id);
        } catch (err) {
          console.log('No signature found for user, creating default now');
        }
        if (signature) {
          if (this.signature !== signature.signature) await this.signatureSvc.update(this.user?.id, this.signature);
        } else await this.signatureSvc.create({ userId: this.user.id, signature: this.signature });
      }

      let purchaseOrSubscription = await this.checkoutSvc.complete(
        preview,
        this.agreementData,
        this.referredById ?? undefined,
        this.transferredFromId ?? undefined
      );
      if ((purchaseOrSubscription as Purchase)?.payment?.paymentMethod?.model === 'terminal') {
        purchaseOrSubscription = await this.purchaseSvc.handleTerminalPayment(purchaseOrSubscription as Purchase);
      }

      if (
        purchaseOrSubscription.status !== PurchaseStatus.PAID &&
        purchaseOrSubscription.status !== SubscriptionStatus.ACTIVE
      ) {
        if (purchaseOrSubscription?.failureReason) {
          this.snacks.open(
            'Something went wrong when processing your purchase. ' +
              purchaseOrSubscription.failureReason +
              ' Please try again!',
            'Ok!',
            {
              duration: 10000,
              panelClass: 'mat-warn',
            }
          );
        } else {
          if (
            (purchaseOrSubscription as Purchase).payment?.status === PaymentStatus.DELAYED_PROCESSING ||
            (purchaseOrSubscription as Subscription).lockedForProcessing === true
          ) {
            if (!this.preventRedirect) await this.router.navigate(['/']);
            this.purchaseSuccess.emit();
            this.snacks.open(
              'Your purchase is processing. Products and services will be available upon completion in 3 or more business days',
              'Ok!',
              {
                duration: 10000,
                panelClass: 'mat-primary',
              }
            );
          } else {
            this.snacks.open('Something went wrong when processing this purchase. Please try again!', 'Ok!', {
              duration: 10000,
              panelClass: 'mat-warn',
            });
          }
        }
      } else {
        if (!this.preventRedirect) await this.router.navigate(['/']);
        this.purchaseSuccess.emit();
        const snack =
          'subscription' in preview
            ? preview.subscription.startDate
              ? 'Subscription started!'
              : 'Subscription scheduled!'
            : 'Purchase completed!';
        this.snacks.open(snack + ' Purchased products and services will be available shortly.', 'Ok', {
          duration: 6000,
          panelClass: 'mat-primary',
        });
      }
      // }
    } catch (err) {
      console.error(err);
      if (err instanceof HttpErrorResponse && err.error.message) {
        this.snacks.open(err.error.message, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
        this.refresh$.next(null);
      } else {
        this.snacks.open('Oops, failed to confirm this purchase!', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      }
    }

    this.confirming = false;
  }

  async ngOnInit() {
    const self = await this.userSvc.getSelf();
    if (self && !this.user) this.user = self;
    this.self = self;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this._user$.complete();
  }
}
