<greco-stats-grid [referenceId]="variant.id"></greco-stats-grid>

<!-- Details -->
<greco-collapsible-section #details [header]="{ title: 'Variant Details', icon: 'info' }">
  <greco-form-save-buttons
    *ngIf="details.expanded"
    header
    [form]="formDetails"
    [saveAction]="saveDetails"
    [resetValue]="resetValueDetails"
    (formReset)="formDetails.markAsPristine()"
  ></greco-form-save-buttons>

  <form [formGroup]="formDetails">
    <mat-form-field appearance="standard">
      <mat-label>Title</mat-label>
      <input matInput type="text" formControlName="title" />
      <mat-hint>Optional title to display instead of the the option values.</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Sort Priority</mat-label>
      <input matInput type="number" formControlName="priority" />
      <mat-hint>Higher priority variants are shown first in the shop.</mat-hint>
    </mat-form-field>

    <!-- Variant Options -->
    <ng-container *ngFor="let option of productOptions">
      <mat-form-field appearance="standard">
        <mat-label>{{option.label}}</mat-label>
        <input matInput type="text" required formControlName="{{option.id}}" />
      </mat-form-field>
    </ng-container>

    <mat-form-field appearance="standard" floatLabel="always">
      <mat-label>Image</mat-label>
      <greco-variant-image-picker
        style="width: 100%"
        [product]="product"
        formControlName="image"
      ></greco-variant-image-picker>
    </mat-form-field>

    <mat-form-field appearance="standard">
      <input matInput style="display: none" />
      <mat-checkbox color="primary" formControlName="isHidden">Hidden In Shop</mat-checkbox>
    </mat-form-field>
  </form>
</greco-collapsible-section>

<!-- Price -->
<greco-collapsible-section #price [header]="{ title: 'Price', icon: 'request_quote' }">
  <greco-form-save-buttons
    *ngIf="price.expanded"
    header
    [form]="formPrice"
    [saveAction]="savePrice"
    [resetValue]="resetValuePrice"
    (formReset)="formPrice.markAsPristine()"
  ></greco-form-save-buttons>

  <form [formGroup]="formPrice">
    <greco-variant-price
      *ngIf="product?.community"
      formControlName="variantPrice"
      [communityId]="product.community.id"
      [isActive]='variant.status === "ACTIVE"'
      [variantId]="variant.id"
      [accountId]="variant.product!.community!.financeAccountId"
    ></greco-variant-price>

    <mat-form-field appearance="standard" *ngIf="isValidForProrateStart">
      <mat-label>Prorate billing (optional)</mat-label>
      <input
        matInput
        [min]="minimalDate"
        [max]="maximalDate"
        [matDatepicker]="picker"
        (click)="picker.open()"
        formControlName="prorateStart"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </form>
</greco-collapsible-section>

<!-- Minimum Commitment -->
<greco-collapsible-section
  *ngIf="formPrice.value.variantPrice?.recurrence"
  #minimumCommitment
  [header]="{ title: 'Minimum Commitment', icon: 'edit_calendar' }"
>
  <greco-form-save-buttons
    *ngIf="minimumCommitment.expanded"
    header
    [form]="minimumCommitmentForm"
    [saveAction]="saveMinimumCommitment"
    [resetValue]="resetMinimumCommitment"
    (formReset)="minimumCommitmentForm.markAsPristine()"
  ></greco-form-save-buttons>

  <form [formGroup]="minimumCommitmentForm">
    <div style="display: flex; align-items: flex-end">
      <mat-form-field>
        <mat-label>Minimum Commitment</mat-label>
        <input matInput type="number" required formControlName="commitmentLength" />
      </mat-form-field>

      <!-- Period -->
      <mat-form-field>
        <mat-label>Period</mat-label>
        <mat-select required formControlName="commitmentPeriod">
          <mat-option value="DAILY">Day(s)</mat-option>
          <mat-option value="WEEKLY">Week(s)</mat-option>
          <mat-option value="MONTHLY">Month(s)</mat-option>
          <mat-option value="YEARLY">Year(s)</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</greco-collapsible-section>

<!-- TODO: Perks -->
<greco-collapsible-section id="perks-section" [header]="{ title: 'Perks', icon: 'local_activity' }">
  <button header mat-flat-button color="primary" style="height: 32px; line-height: 16px" (click)="addVariantPerk()">
    <mat-icon style="margin-left: -4px">add</mat-icon>
    <span>Perk</span>
  </button>

  <greco-table
    *ngIf="variant.variantPerks?.length"
    [data]="variant.variantPerks"
    [highlight]="true"
    (rowClick)="updateVariantPerk($event)"
  >
    <ng-container *grecoTableCol="''; let variantPerk; fitContent: true">
      <greco-perk-badge
        *ngIf="variantPerk?.perk"
        [small]="true"
        [quantity]="variantPerk.quantity"
        [text]="variantPerk.perk.badge.text"
        [color]="variantPerk.perk.badge.color"
        [icon]="variantPerk.perk.badge.icon"
        [shape]="variantPerk.perk.badge.shape"
        [matTooltip]="variantPerk.id + ' - ' + variantPerk.perk.id"
      ></greco-perk-badge>
    </ng-container>

    <p *grecoTableCol="'Perk'; let variantPerk">{{variantPerk.perk.title}}</p>

    <p *grecoTableCol="'Quantity'; let variantPerk">
      {{variantPerk.quantity}}
      <!-- TODO(adaoust): Connect reusable perks -->
      <mat-icon
        matTooltip="Reusable (Does not get consumed on use)"
        *ngIf="variantPerk.reusable"
        style="vertical-align: bottom; font-size: 20px; width: 20px; height: 20px"
      >
        restart_alt
      </mat-icon>
    </p>

    <p *grecoTableCol="'Granted'; let variantPerk">{{variantPerk | perks: 'granted' | titlecase}}</p>

    <p *grecoTableCol="'Expires'; let variantPerk">{{variantPerk | perks: 'expiry' | titlecase}}</p>

    <p *grecoTableCol="'Expiry Notification'; let variantPerk">{{ variantPerk | perks: 'expiryDelay' | titlecase }}</p>

    <mat-icon *grecoTableCol="'Transferable'; let variantPerk; alignCenter: true" [matTooltip]="''">
      {{variantPerk.transferable ? 'check_circle' : 'remove_circle_outline'}}
    </mat-icon>

    <p *grecoTableCol="'Freezability'; let variantPerk">
      {{ variantPerk.freezable ? 'Unusable during freeze' : 'Usable during freeze' }}
    </p>

    <p *grecoTableCol="'Usage Fee'; let variantPerk; alignRight: true" [matTooltip]="''">
      <ng-container *ngIf="variantPerk.fee.amount; else noFee">
        <ng-container *ngIf="product?.community?.financeAccount?.id === variantPerk.fee.sourceAccount.id">
          Pay <strong>{{variantPerk.fee.amount / 100 | currency}}</strong>
        </ng-container>
        <ng-container *ngIf="product?.community?.financeAccount?.id === variantPerk.fee.destinationAccount.id">
          Receive <strong>{{variantPerk.fee.amount / 100 | currency}}</strong>
        </ng-container>
      </ng-container>
      <ng-template #noFee>-</ng-template>
    </p>

    <mat-chip-list *grecoTableCol="'Status'; let variantPerk; fitContent: true">
      <mat-chip>{{variantPerk.status | titlecase}}</mat-chip>
    </mat-chip-list>

    <ng-container *grecoTableCol="''; let variantPerk; fitContent: true; stickyEnd: true">
      <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopImmediatePropagation()">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu>
        <!-- Archive (only if active) -->
        <button *ngIf="variantPerk.status === 'ACTIVE'" mat-menu-item (click)="archive(variantPerk)">
          <mat-icon>archive</mat-icon>
          <span>Archive</span>
        </button>

        <!-- Activate -->
        <button *ngIf="variantPerk.status !== 'ACTIVE'" mat-menu-item (click)="activate(variantPerk)">
          <mat-icon>check_circle</mat-icon>
          <span>{{variantPerk.status === 'DRAFT' ? 'Activate' : 'Re-activate'}}</span>
        </button>

        <!-- Remove (only if draft) -->
        <button *ngIf="variantPerk.status === 'DRAFT'" mat-menu-item (click)="removeVariantPerk(variantPerk)">
          <mat-icon>delete</mat-icon>
          <span>Remove</span>
        </button>

        <!-- Update -->
        <button mat-menu-item (click)="updateVariantPerk(variantPerk)">
          <mat-icon>update</mat-icon>
          <span>Update</span>
        </button>
      </mat-menu>
    </ng-container>
  </greco-table>
</greco-collapsible-section>

<!-- TODO: Transfers & Fees -->
<greco-collapsible-section id="transfers-section" [header]="{ title: 'Fees & Referrals', icon: 'swap_horiz' }">
  <button
    header
    mat-flat-button
    color="primary"
    style="height: 32px; line-height: 16px"
    (click)="createVariantTransfer($event)"
  >
    <mat-icon style="margin-left: -4px">add</mat-icon>
    <span>Fee/Referral</span>
  </button>

  <greco-variant-transfers-table [product]="product" [variant]="variant"></greco-variant-transfers-table>
</greco-collapsible-section>
